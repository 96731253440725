<template>
  <header class="header">
    <div class="container">
      <div class="header__wrapper">
        <div class="header__logo-wrapper">
          <router-link :to="HOME_ROUTE">
            <iconLogo class="header__logo" />
          </router-link>
        </div>
        <div class="header__content">
          <elk-breadcrumbs
            v-if="isMoreThan1024"
            :page-name="pageName"
          />
          <div class="header__settings header-settings">
            <!--            выразительная кнопка -->
            <div
              v-if="userHasAccessSuggestions"
              class="header-settings__item"
            >
              <router-link
                :to="USER_SUGGETSTION_ROUTE"
                class="header-settings__item-container primary-dusk-1-text"
              >
                <feedback-hotline class="header-settings__logo" />
                <span class="header-settings__text hidden md:block">
                  {{ $t(getCommonCaptionByName('feedbackHotlineTitle')) }}
                </span>
              </router-link>
            </div>

            <div class="header-settings__item">
              <div class="header-settings__lang-wrapper">
                <div class="header-settings__lang">
                  <localeChanger />
                </div>
              </div>
            </div>
            <!-- new element menu header -->
            <div
              v-if="
                isAuth &&
                ($store.getters.userDpo || $store.getters.userRepresent)
              "
              class="header-settings__item"
            >
              <link-btn-header />
            </div>
            <div
              v-if="
                isAuth &&
                ($store.getters.userDpo || $store.getters.userRepresent)
              "
              class="header-settings__item"
            >
              <link-btn-menu-header />
            </div>
            <div
              v-if="
                isAuth &&
                ($store.getters.userDpo || $store.getters.userRepresent)
              "
              class="header-settings__item"
            >
              <link-basket-header />
            </div>
            <!-- <div class="header-settings__item">
              <likn-gear-header />
            </div> -->
            <!-- new element menu header -->
            <div
              v-if="$route.path === HOME_ROUTE"
              class="header-settings__item"
            >
              <div
                :class="{'js-active': isSettingOpen}"
                class="link header-settings__link"
                @click="openSetting"
              >
                <el-popover
                  :content="$t(getCommonCaptionByName('manageWidgets'))"
                  placement="bottom"
                  trigger="hover"
                >
                  <iconGear
                    slot="reference"
                    class="header-settings__logo"
                  />
                </el-popover>
              </div>
            </div>
            <div
              v-if="feedbackPageBlockName"
              class="header-settings__item"
            >
              <template v-if="isPublicUser">
                <a
                  class="text-gray-300 flex items-center font-semibold group"
                  href="#3"
                  @click.prevent="showSupportNotAuthorizedUser"
                >
                  <iconFeedback class="header-settings__logo" />
                </a>
              </template>
              <template v-else>
                <a
                  class="link header-settings__link"
                  :href="supportLink"
                  target="_blank"
                >
                  <el-popover
                    :content="$t(getCommonCaptionByName('supportCaption'))"
                    placement="bottom"
                    trigger="hover"
                  >
                    <template #reference>
                      <iconFeedback class="header-settings__logo" />
                    </template>
                  </el-popover>
                </a>
              </template>
            </div>
            <div class="header-settings__item">
              <div class="header-settings__item-user">
                <template v-if="!isPublicUser">
                  <a
                    class="link header-settings__link"
                    @click="logOut"
                  >
                    <el-popover
                      :content="$t(getCommonCaptionByName('auth_logout'))"
                      placement="bottom"
                      trigger="hover"
                    >
                      <div slot="reference">
                        <component
                          :is="iconLoader('elk.logout')"
                          class="header-settings__logo header-settings__logo-exit"
                        />
                      </div>
                    </el-popover>
                  </a>
                  <router-link
                    v-if="profilePageBlockName"
                    :to="PROFILE_ROUTE"
                    active-class="choosen"
                    class="header-settings__exit"
                  >
                    <el-popover
                      :content="profilePageBlockName"
                      placement="bottom"
                      trigger="hover"
                    >
                      <div slot="reference">
                        <avatar
                          :fio-separate="[
                            $store.state.user.first_name,
                            $store.state.user.last_name
                          ]"
                          :gender-id="$store.state.user.gender_code"
                          :url="$store.state.user.avatar"
                          class="header-settings__logo header-settings__logo-user"
                          size="24"
                        />
                      </div>
                    </el-popover>
                  </router-link>
                </template>
                <template v-else>
                  <a
                    :href="getAdfsUrl"
                    class="link header-settings__login primary-dusk-1-text"
                  >
                    {{ $t(getCommonCaptionByName('auth_toLogin')) }}
                  </a>
                  <router-link
                    :to="REGISTRATION_ROUTE"
                    class="link primary-dusk-1-text"
                  >
                    {{ $t(getCommonCaptionByName('auth_toRegister')) }}
                  </router-link>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ElkModalSupportNotAuthorizedUser ref="modalSupport" />
  </header>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import loader from '@/utils/widgetLoader';
import {
  HOME_ROUTE,
  IT_SUPPORT,
  PROFILE_ROUTE,
  USER_SUGGETSTION_ROUTE,
  IT_SUPPORT_DPO,
  IT_SUPPORT_REPRESENT
} from '@/router/routes';

import iconLogo from '@/assets/icons/smartPoint-2.svg?inline';

import localeChanger from '@/components/common/localeChanger';
import elkBreadcrumbs from './elkBreadcrumbs';
import avatar from '@/components/common/elkAvatar';

import iconLoader from '@/utils/iconLoader';

import iconFeedback from '@/assets/icons/menuIcons/feedbackLine.svg?inline';
import iconGear from '@/assets/icons/menuIcons/homeSetting.svg?inline';
import feedbackHotline from '@/assets/icons/menuIcons/feedbackHotline.svg?inline';

import logOut from '@/utils/logOut';
import {REGISTRATION_ROUTE} from '@/router/routes.js';

const PROFILE_PAGE_SIGNATURE = 'elk.profile';
const FEEDBACK_PAGE_SIGNATURE = 'elk.feedback';
import ElkModalSupportNotAuthorizedUser from '@/components/elkHeader/elkModalSupportNotAuthorizedUser.vue';

import {getUiItemCaption, getCommonCaptionByName} from '@/i18n/utils';
import LinkBtnHeader from '@/components/common/commonLinkBtnHeader/linkLikeHeader.vue';
import LinkBtnMenuHeader from '@/components/common/commonLinkBtnHeader/linkBtnMenuHeader.vue';
import LinkBasketHeader from '@/components/common/commonLinkBtnHeader/linkBasketHeader.vue';
import LiknGearHeader from '@/components/common/commonLinkBtnHeader/liknGearHeader.vue';

export default {
  name: 'ElkHeader',
  components: {
    LiknGearHeader,
    LinkBasketHeader,
    LinkBtnMenuHeader,
    LinkBtnHeader,
    iconLogo,
    feedbackHotline,
    localeChanger,
    elkBreadcrumbs,
    iconFeedback,
    avatar,
    iconGear,
    ElkModalSupportNotAuthorizedUser
  },
  props: ['pageName'],
  data() {
    return {
      REGISTRATION_ROUTE,
      USER_SUGGETSTION_ROUTE,
      HOME_ROUTE,
      IT_SUPPORT,
      PROFILE_ROUTE,
      modalSupportNotAuthorizedUser: false,
      IT_SUPPORT_DPO,
      IT_SUPPORT_REPRESENT
    };
  },
  computed: {
    ...mapState(['isSettingOpen', 'width']),
    ...mapGetters([
      'isPublicUser',
      'getAdfsUrl',
      'isAuth',
      'userHasAccessSuggestions',
      'userRoles'
    ]),
    isMoreThan1024() {
      return this.width > 1024;
    },
    widgets() {
      return this.$store.getters['getWidgets'];
    },
    profilePageBlockName() {
      return this.widgets?.get(PROFILE_PAGE_SIGNATURE)?.block_name ?? null;
    },
    feedbackPageBlockName() {
      return this.widgets?.get(FEEDBACK_PAGE_SIGNATURE)?.block_name ?? null;
    },
    isOnlyDdoRole() {
      return this.userRoles.length === 1 && this.userRoles.includes('dpo');
    },
    userRolesLength() {
      return this.userRoles.length;
    },
    isRepresentRole() {
      const availableRoles = [['represent'], ['represent', 'company_admin']];

      return availableRoles.some(roles => {
        return roles.every(
          role =>
            this.userRoles.includes(role) &&
            roles.length === this.userRolesLength
        );
      });
    },
    supportLink() {
      switch (true) {
        case this.isOnlyDdoRole:
          return IT_SUPPORT_DPO;
        case this.isRepresentRole:
          return IT_SUPPORT_REPRESENT;
        default:
          return IT_SUPPORT;
      }
    }
  },
  methods: {
    getCommonCaptionByName,
    loader,
    getUiItemCaption,
    iconLoader,
    logOut,
    openSetting() {
      let widgetSetting = this.$parent.$parent.$refs['widget-setting'];

      widgetSetting.removeChange();

      if (this.isSettingOpen) {
        window.scrollTo(0, 0);
      }
    },
    showSupportNotAuthorizedUser() {
      this.$refs.modalSupport.openModal();
    }
  }
};
</script>

<style lang="scss">
.header-settings__item-user {
  align-items: flex-start !important;
}
.custom-popover {
  transform: translate(-30px, 15px) !important;
}
</style>
