<template>
  <error-page-public v-if="isPublicUser" />
  <div
    v-else
    :class="{body: true, active: menuActive}"
  >
    <menu-wrapper :page-name="localeText.title" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <loading v-if="isLoading || isLoadUser" />
        <div
          v-else
          class="container"
          :class="{leftMenu: true, openLeftMenu: menuActive}"
        >
          <template v-if="!isEmpty">
            <div class="widget--basket">
              <h1>
                {{ localeText.title }}
              </h1>
              <div
                v-if="!isEmpty"
                class="wrapper-delete-all__basket hidden__desktop-basket"
              >
                <button
                  class="btn btn__delete-all-elements"
                  @click="onClearBasket"
                >
                  {{ localeText.deleteAll }}
                </button>
              </div>
            </div>
            <section class="section-basket">
              <div class="wrapper-basket__table">
                <div class="header-basket__table">
                  <div class="basket__table-program">
                    {{ localeText.programs }}:
                    <span>
                      {{ counterCartItems }}
                    </span>
                  </div>
                  <div>{{ localeText.basketStartProgram }}</div>
                  <div>{{ localeText.basketSpecializations }}</div>
                  <!--                  <div>Продолжительность</div>-->
                  <div>{{ localeText.cost }}</div>
                  <!--                  <div />-->
                  <div class="wrapper-delete-all__basket">
                    <button
                      class="btn btn__delete-all-elements"
                      @click="onClearBasket"
                    >
                      {{ localeText.deleteAll }}
                    </button>
                  </div>
                </div>
                <div class="basket-container__table">
                  <div
                    v-for="program in items"
                    :key="program.id"
                    class="body-basket__table"
                  >
                    <div class="basket__table-cell basket__table-title">
                      <alert-attention
                        v-if="program.is_ordered"
                        :text="localeText.tooltipReOrder"
                      />

                      <a
                        :href="program.detail_url"
                        target="_blank"
                      >
                        <h6 class="h6-basket h6-economy">
                          <div
                            v-if="program.fin_literacy"
                            class="basket__table-tag"
                          >
                            {{ localeText.badgeFinancialLiteracy }}
                          </div>
                          <span class="link">{{ program.title }}</span>
                        </h6>
                      </a>

                      <p v-if="program.direction">
                        {{ localeText.specializationPrefix }} {{ program.direction }}
                      </p>
                    </div>
                    <div class="basket__table-cell basket__table-start">
                      <span class="title__element-hidden-desktop">
                        {{ localeText.basketStartProgram }}
                      </span>
                      <div>
                        {{
                          getNearestSchedule(program.schedule)?.dateStartStr ??
                          '-'
                        }}
                      </div>
                    </div>
                    <div
                      class="basket__table-cell basket__table-specializations"
                    >
                      <span class="title__element-hidden-desktop">
                        {{ localeText.basketSpecializations }}
                      </span>
                      <div>
                        {{ program.childrenCount || '-' }}
                      </div>
                    </div>
                    <!--                    <div class="basket__table-cell basket__table-duration">-->
                    <!--                      <span class="title__element-hidden-desktop"-->
                    <!--                        >Продолжительность</span-->
                    <!--                      >-->
                    <!--                      <div>-->
                    <!--                        {{ program.duration }}-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                    <div class="basket__table-cell basket__table-price">
                      <span class="title__element-hidden-desktop">
                        {{ localeText.cost }}
                      </span>
                      <div class="cell__price">
                        <template
                          v-if="program.price?.start || program.price?.end"
                        >
                          <span
                            v-if="program.price?.start"
                            class="discount"
                          >
                            {{ program.price?.start }} ₽
                          </span>
                          <span
                            v-if="program.price?.end"
                            class="all-price"
                          >
                            {{ program.price?.end }} ₽
                          </span>
                        </template>
                        <span
                          v-if="!program.price?.end"
                          class="free"
                        >
                          {{ localeText.free }}!
                        </span>
                      </div>
                    </div>

                    <!--                    <div class="basket__table-cell basket__table-buttons" />-->
                    <div class="basket__table-cell basket__table-buttons">
                      <button
                        v-if="userRepresent"
                        :disabled="
                          !completelyFilled ||
                          (!program.schedule.length &&
                              !program.childrenCount) ||
                          program.is_ordered_processing
                        "
                        class="btn btn--checkout__basket btn-with-hint"
                        :class="{'btn--primary-disabled': !completelyFilled}"
                        @click="onOrder(program.id)"
                      >
                        <span class="btn-with-hint__trigger">
                          {{ localeText.btnCheckout }}
                        </span>
                        <span
                          v-if="!completelyFilled"
                          class="btn-with-hint__hint"
                        >
                          {{ localeText.tooltipFillProfile }}
                        </span>
                        <span
                          v-else-if="
                            !program.schedule.length && !program.childrenCount
                          "
                          class="btn-with-hint__hint"
                        >
                          {{ localeText.tooltipNoSchedule }}
                        </span>
                      </button>
                      <button
                        v-else
                        :disabled="
                          !isCanOrder ||
                          (!program.schedule.length &&
                              !program.childrenCount) ||
                          program.is_ordered_processing ||
                          program.is_edu_level_need
                        "
                        class="btn btn--checkout__basket btn-with-hint"
                        :class="{
                          'btn--primary-disabled': !isCanOrder,
                          'z-0': !program.is_edu_level_need,
                          'z-20': program.is_edu_level_need
                        }"
                        @click="onOrder(program.id)"
                      >
                        <span class="btn-with-hint__trigger">
                          {{ localeText.btnCheckout }}
                        </span>
                        <span
                          v-if="!isCanOrder"
                          class="btn-with-hint__hint"
                        >
                          {{ localeText.tooltipFillProfile }}
                        </span>
                        <span
                          v-else-if="
                            !program.schedule.length && !program.childrenCount
                          "
                          class="btn-with-hint__hint"
                        >
                          {{ localeText.tooltipNoSchedule }}
                        </span>
                        <span
                          v-else-if="program.is_ordered_processing"
                          class="btn-with-hint__hint"
                        >
                          {{ localeText.tooltipOrderProcessing }}
                        </span>
                        <span
                          v-else-if="program.is_edu_level_need"
                          class="btn-with-hint__hint hint_overflow"
                        >
                          {{
                            localeText.eduLevelNeed +
                            ' ' +
                            program.is_edu_level_need
                          }}
                        </span>
                      </button>
                      <button
                        class="btn btn--delete-cell"
                        @click="onRemoveBasketItem(program.id)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </template>
          <section v-else>
            <empty-wrapper>
              <empty-data-message :text="localeText.emptyBasket" />
            </empty-wrapper>
          </section>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import Loading from '@/components/common/elkLoading';
import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import EmptyWrapper from '@/components/common/elkEmptyWrapper';
import menuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import {ORDER, PROFILE_ROUTE} from '@/router/routes';
import {mapState, mapGetters, mapActions} from 'vuex';
import ErrorPagePublic from '../404/index.vue';
import AlertAttention from '@/components/newAlerts/alertAttention.vue';
import localization from '@/i18n/localization';

export default {
  name: 'NewBasket',
  components: {
    AlertAttention,
    ErrorPagePublic,
    EmptyWrapper,
    PageFooter,
    menuWrapper,
    EmptyDataMessage,
    Loading
  },
  data() {
    return {
      name: 'Корзина',
      linkProfile: PROFILE_ROUTE,
      pageClass: 'basket-page',
      localization: localization('dpo.basket')
    };
  },
  computed: {
    ...mapState({
      menuActive: state => state.menuActive,
      items: state => state.cart.cartItems
    }),
    ...mapGetters('representProfileLegal', ['completelyFilled']),
    ...mapGetters([
      'isPublicUser',
      'isAuth',
      'isCanOrder',
      'isLoadUser',
      'userRepresent',
      'userDpo',
      'getLinkUrlBySignature'
    ]),
    ...mapGetters('cart', ['counterCartItems', 'isEmpty', 'isLoading']),
    localeText() {
      return {
        title: this.localization.widgetCaption('basketTitle'),
        deleteAll: this.localization.widgetCaption('deleteAll'),
        eduLevelNeed: this.localization.widgetCaption('eduLevelNeed'),
        basketStartProgram:
          this.localization.widgetCaption('basketStartProgram'),
        basketSpecializations: this.localization.widgetCaption(
          'basketSpecializations'
        ),
        tooltipReOrder: this.localization.widgetCaption('tooltipReOrder'),
        badgeFinancialLiteracy: this.localization.widgetCaption(
          'badgeFinancialLiteracy'
        ),
        btnCheckout: this.localization.widgetCaption('btnCheckout'),
        tooltipFillProfile:
          this.localization.widgetCaption('tooltipFillProfile'),
        tooltipNoSchedule: this.localization.widgetCaption('tooltipNoSchedule'),
        tooltipOrderProcessing: this.localization.widgetCaption(
          'tooltipOrderProcessing'
        ),
        emptyBasket: this.localization.widgetCaption('emptyBasket'),
        programs: this.localization.widgetCaption('programs'),
        cost: this.localization.commonCaption('cost'),
        free: this.localization.commonCaption('free'),
        specializationPrefix: this.localization.widgetCaption('specializationPrefix')
      };
    }
  },
  mounted() {
    this.$store.dispatch('cart/init');
  },
  async created() {
    if (this.userDpo) {
      Promise.all([this['profileFillCheck/getProfileFillCheck']()]).catch();
    }
  },
  methods: {
    ...mapActions(['profileFillCheck/getProfileFillCheck']),
    showNotify(message, typeMessage = 'info') {
      const h = this.$createElement;

      this.$notify({
        title: '',
        type: typeMessage,
        message: h('i', {}, message)
      });
    },
    async onClearBasket() {
      try {
        this.$store.dispatch('cart/clearCart');
      } catch (e) {
        this.showNotify(e.response?.data?.message ?? '', 'error');
        /// Не удалось очистить корзину
      }
    },
    async onRemoveBasketItem(id) {
      try {
        this.$store.dispatch('cart/remove', id);
      } catch (e) {
        this.showNotify(e.response?.data?.message ?? '', 'error');
        /// Не удалось удалить товар из корзины
      }
    },
    getNearestSchedule(schedule) {
      if (Array.isArray(schedule) && schedule.length) {
        let diffTime = new Date(schedule[0].dateStart).getTime();
        let indexNearest = 0;
        schedule.forEach((e, i) => {
          const time = new Date(e.dateStart).getTime();
          if (diffTime > time) {
            diffTime = time;
            indexNearest = i;
          }
        });
        return schedule[indexNearest];
      }
      return null;
    },
    onOrder(id) {
      if (this.userRepresent) {
        const url = this.getLinkUrlBySignature('dpo.new.application.form');
        if (url) {
          this.$router.push({
            path: `${url}?id=${id}`
          });
        }
      } else {
        this.$router.push({
          path: `${ORDER}/${id}`
        });
      }
    }
  }
};
</script>

<style lang="scss">
.hint_overflow {
  width: 350px !important;
  white-space: normal !important;
  word-wrap: break-word !important;
  overflow-wrap: break-word !important;
}
.widget--basket {
  .wrapper-delete-all__basket.hidden__desktop-basket {
    display: none;
  }
}
.container {
  .section-basket {
    margin-top: 32px;
    .header-basket__table {
      display: grid;
      align-items: center;
      // grid-template-columns: 450px 1fr 1fr 1fr 1fr 347px;
      grid-template-columns: 450px 1fr 1fr 1fr 1fr;
      column-gap: 16px;
      margin-bottom: 13px;
      padding-left: 20px;
      & > div:nth-child(2) {
        padding-left: 16px;
      }
      & > div:not(:first-child) {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #6b7a99;
      }
      .wrapper-delete-all__basket {
        display: flex;
        justify-content: flex-end;
        .btn.btn__delete-all-elements {
          width: auto;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #6b7a99;
          position: relative;
          background-color: transparent;
          border: none;
          padding: 0 0 0 10px;
          display: flex;
          align-items: center;
          &:before {
            content: '';
            display: block;
            width: 10px;
            height: 12px;
            background: url('../../assets/icons/icon-trash.svg') 100% 100%
              no-repeat;
            margin-right: 5px;
            position: relative;
            top: -2px;
          }
        }
      }
      .basket__table-program {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #0f0f14;
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #0f0f14;
          margin-left: 15px;
        }
      }
    }
    .basket-container__table {
      background-color: #ffffff;
      box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
      border-radius: 4px;
      padding: 24px 20px;
    }
    .body-basket__table {
      display: grid;
      align-items: center;
      // grid-template-columns: 450px 1fr 1fr 1fr 1fr 327px;
      grid-template-columns: 450px 1fr 1fr 1fr 1fr;
      column-gap: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #edeff3;
      .basket__table-cell {
        height: auto;
        padding: 5px 0;
      }
      .h6-basket {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
        &.h6-economy {
          color: $base-black-color;
        }
        &.h6-education-science {
          color: #a348ff;
        }
        &.h6-management-education {
          color: #dd0000;
        }
        &.h6-management-psychology {
          color: #2079ff;
        }
      }
      .basket__table-tag {
        display: inline-block;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        color: $color-gray-dusk;
        border: 1px solid $color-gray-dusk-5;
        border-radius: 4px;
        padding: 6px 8px 6px 8px;
        margin-right: 8px;
      }
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $base-black-color;
        margin-bottom: 0;
      }
      .basket__table-start {
        border-left: 1px solid #edeff3;
        border-right: 1px solid #edeff3;
        position: relative;
        padding-left: 16px;
        height: 100%;
        display: flex;
        align-items: center;
        div {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $base-black-color;
        }
      }
      .basket__table-duration {
        height: 100%;
        display: flex;
        align-items: center;
        border-right: 1px solid #edeff3;
        div {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $base-black-color;
        }
      }
      .basket__table-cell {
        .title__element-hidden-desktop {
          display: none;
        }
      }
      .basket__table-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 18px;
      }
      .basket__table-price {
        .cell__price {
          display: flex;
          flex-direction: column;
          .all-price {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: $base-black-color;
          }
          .discount {
            position: relative;
            overflow: hidden;
            width: fit-content;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #6b7a99;
            margin-bottom: 5px;
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #ffffff;
              border-top: 2px solid #6b7a99;
              transform: translateY(-50%) rotate(-9deg);
            }
          }
          .free {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #28c75d;
          }
        }
      }
      .basket__table-buttons {
        .btn.btn--checkout__basket {
          max-width: 162px;
          height: 42px;
        }
        .btn.btn.btn--delete-cell {
          background-color: transparent;
          width: 42px;
          height: 42px;
          padding: 0 5px;
          position: relative;
          box-sizing: border-box;
          &:before {
            content: '';
            position: absolute;
            top: calc(50% - 7px);
            left: calc(50% - 7px);
            width: 14px;
            height: 14px;
            display: block;
            background: url('../../assets/icons/icon-close-gray.svg') 100% 100%
              no-repeat;
          }
        }
      }
      &:last-child {
        border-bottom: unset;
        padding-bottom: 0;
      }
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
}

// media

@media (max-width: 1440px) {
  .section-basket {
    .body-basket__table {
      //grid-template-columns: 430px 1fr 1fr 1fr 210px;
      grid-template-columns: 430px 1fr 1fr 1fr 1fr;
    }
    .header-basket__table {
      grid-template-columns: 430px 1fr 1fr 1fr 1fr;
      //grid-template-columns: 430px 1fr 1fr 1fr 230px;
    }
  }
}

@media (max-width: 1400px) {
  .section-basket {
    .body-basket__table {
      .h6-basket {
        font-size: 12px;
        line-height: 16px;
      }
      p {
        font-size: 14px;
        line-height: 20px;
      }
      .basket__table-price {
        .cell__price {
          .all-price {
            font-size: 14px;
            line-height: 20px;
          }
          .free {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      .basket__table-buttons {
        .btn.btn--checkout__basket {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
  .container {
    .section-basket {
      .header-basket__table {
        //grid-template-columns: 400px 1fr 1fr 1fr 270px;
        grid-template-columns: 400px 1fr 1fr 1fr 1fr;
      }
      .basket-container__table {
        .body-basket__table {
          grid-template-columns: 400px 1fr 1fr 1fr 1fr;
          //grid-template-columns: 400px 1fr 1fr 1fr 250px;
        }
      }
    }
  }
}

@media (max-width: 1399px) {
  .container.leftMenu.openLeftMenu {
    .widget--basket {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .wrapper-delete-all__basket.hidden__desktop-basket {
        display: block;
        .btn.btn__delete-all-elements {
          width: auto;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #6b7a99;
          position: relative;
          background-color: transparent;
          border: none;
          padding: 0 0 0 10px;
          display: flex;
          align-items: center;
          &:before {
            content: '';
            display: block;
            width: 10px;
            height: 12px;
            background: url('../../assets/icons/icon-trash.svg') 100% 100%
              no-repeat;
            margin-right: 5px;
            position: relative;
            top: -2px;
          }
        }
      }
    }
    .section-basket {
      .header-basket__table {
        display: none;
      }
      .basket-container__table {
        background-color: transparent;
        box-shadow: unset;
        border-radius: 0;
        padding: 0;
      }
      .body-basket__table {
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 0;
        background-color: #ffffff;
        box-shadow: 0 1px 2px rgb(106 122 152 / 15%);
        border-radius: 4px;
        padding: 24px 20px;
        margin-bottom: 8px;
        border-bottom: unset;
        position: relative;
        .basket__table-cell:nth-child(1) {
          grid-row-start: 1;
          grid-column-start: 1;
          grid-row-end: 2;
          grid-column-end: 7;
          padding: 0 0 16px 0;
        }
        .basket__table-cell:nth-child(2),
        .basket__table-cell:nth-child(3) {
          grid-row-start: 2;
          grid-column-start: 1;
          grid-row-end: 3;
          grid-column-end: 3;
          flex-direction: column;
          align-items: baseline;
          justify-content: center;
          .title__element-hidden-desktop {
            display: block;
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 16px;
            color: #6b7a99;
          }
        }
        .basket__table-cell:nth-child(4) {
          grid-row-start: 2;
          grid-column-start: 1;
          grid-row-end: 3;
          grid-column-end: 2;
          padding-left: 16px;
          flex-direction: column;
          align-items: baseline;
          justify-content: center;
          .title__element-hidden-desktop {
            display: block;
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 16px;
            color: #6b7a99;
          }
        }
        //.basket__table-cell:nth-child(4) {
        //  grid-row-start: 2;
        //  grid-column-start: 5;
        //  grid-row-end: 3;
        //  grid-column-end: 7;
        //  border-right: 1px solid #edeff3;
        //  padding-left: 16px;
        //  flex-direction: column;
        //  align-items: baseline;
        //  justify-content: center;
        //  .title__element-hidden-desktop {
        //    display: block;
        //    margin-bottom: 8px;
        //    font-size: 12px;
        //    line-height: 16px;
        //    color: #6b7a99;
        //  }
        //  .cell__price {
        //    flex-direction: row-reverse;
        //    .all-price {
        //      margin-right: 10px;
        //    }
        //  }
        //}
        .basket__table-cell:nth-child(5) {
          grid-row-start: 3;
          grid-column-start: 1;
          grid-row-end: 4;
          grid-column-end: 7;
          padding: 16px 0 0 0;
          .btn.btn--delete-cell {
            position: absolute;
            top: 24px;
            right: 20px;
          }
          .btn.btn--checkout__basket {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .container.leftMenu {
    .widget--basket {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .wrapper-delete-all__basket.hidden__desktop-basket {
        display: block;
        .btn.btn__delete-all-elements {
          width: auto;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #6b7a99;
          position: relative;
          background-color: transparent;
          border: none;
          padding: 0 0 0 10px;
          display: flex;
          align-items: center;
          &:before {
            content: '';
            display: block;
            width: 10px;
            height: 12px;
            background: url('../../assets/icons/icon-trash.svg') 100% 100%
              no-repeat;
            margin-right: 5px;
            position: relative;
            top: -2px;
          }
        }
      }
    }
    .section-basket {
      .header-basket__table {
        display: none;
      }
      .basket-container__table {
        background-color: transparent;
        box-shadow: unset;
        border-radius: 0;
        padding: 0;
      }
      .body-basket__table {
        grid-template-rows: 1fr 1fr 1fr auto;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 0;
        background-color: #ffffff;
        box-shadow: 0 1px 2px rgb(106 122 152 / 15%);
        border-radius: 4px;
        padding: 24px 20px;
        margin-bottom: 8px;
        border-bottom: unset;
        position: relative;
        .basket__table-cell:nth-child(1) {
          grid-row-start: 1;
          grid-column-start: 1;
          grid-row-end: 2;
          grid-column-end: 5;
          padding: 0 0 16px 0;
        }
        .basket__table-cell:nth-child(2) {
          grid-row-start: 2;
          grid-column-start: 1;
          grid-row-end: 3;
          grid-column-end: 2;
        }

        .basket__table-cell:nth-child(3) {
          grid-row-start: 2;
          grid-column-start: 3;
          grid-row-end: 3;
          grid-column-end: 4;
        }

        .basket__table-cell:nth-child(2),
        .basket__table-cell:nth-child(3) {
          flex-direction: column;
          align-items: baseline;
          justify-content: center;
          border-left: none;
          .title__element-hidden-desktop {
            display: block;
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 16px;
            color: #6b7a99;
          }
        }

        .basket__table-cell:nth-child(4) {
          grid-row-start: 3;
          grid-column-start: 1;
          grid-row-end: 3;
          grid-column-end: 5;
          padding-left: 16px;
          flex-direction: column;
          align-items: baseline;
          justify-content: center;
          .title__element-hidden-desktop {
            display: block;
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 16px;
            color: #6b7a99;
          }
        }
        //.basket__table-cell:nth-child(4) {
        //  grid-row-start: 3;
        //  grid-column-start: 1;
        //  grid-row-end: 4;
        //  grid-column-end: 5;
        //  border-right: 1px solid #edeff3;
        //  height: 100%;
        //  display: flex;
        //  padding-left: 16px;
        //  flex-direction: column;
        //  align-items: baseline;
        //  justify-content: center;
        //  .title__element-hidden-desktop {
        //    display: block;
        //    margin-bottom: 8px;
        //    font-size: 12px;
        //    line-height: 16px;
        //    color: #6b7a99;
        //  }
        //  .cell__price {
        //    flex-direction: row-reverse;
        //    .all-price {
        //      margin-right: 10px;
        //    }
        //  }
        //}
        //.basket__table-cell:nth-child(5) {
        //  grid-row-start: 3;
        //  grid-column-start: 1;
        //  grid-row-end: 4;
        //  grid-column-end: 7;
        //  padding: 16px 0 0 0;
        //  .btn.btn--delete-cell {
        //    position: absolute;
        //    top: 24px;
        //    right: 6px;
        //  }
        //  .btn.btn--checkout__basket {
        //    max-width: 100%;
        //  }
        //}
        .basket__table-cell:nth-child(5) {
          grid-row-start: 4;
          grid-column-start: 1;
          grid-row-end: 4;
          grid-column-end: 5;
          padding: 16px 0 0 0;
          .btn.btn--delete-cell {
            position: absolute;
            top: 24px;
            right: 6px;
          }
          .btn.btn--checkout__basket {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .container.leftMenu {
    .widget--basket {
      h1 {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .section-basket {
      margin-top: 24px;
      .basket-container__table {
        .body-basket__table {
          grid-template-rows: 1fr auto auto auto;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

          gap: 0;
          .h6-basket {
            font-size: 12px;
            line-height: 16px;
          }
          p {
            font-size: 14px;
            line-height: 20px;
          }
          .btn.btn--delete-cell {
            display: none;
          }
          .basket__table-cell:nth-child(1) {
            grid-row-start: 1;
            grid-column-start: 1;

            grid-row-end: 2;
            grid-column-end: 7;

            border-bottom: 1px solid #edeff3;
            margin-bottom: 5px;
            padding: 0 0 10px 0;
          }
          .basket__table-cell:nth-child(2) {
            grid-row-start: 2;
            grid-column-start: 1;

            grid-row-end: 3;
            grid-column-end: 4;

            border-left: unset;
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
          }
          .basket__table-cell:nth-child(3) {
            grid-row-start: 2;
            grid-column-start: 4;

            grid-row-end: 3;
            grid-column-end: 7;

            border-right: unset;
            padding-top: 0;
            padding-bottom: 0;
          }
          .basket__table-cell:nth-child(4) {
            grid-row-start: 3;
            grid-column-start: 1;

            grid-row-end: 4;
            grid-column-end: 7;
            border-right: unset;

            border-top: 1px solid #edeff3;
            margin-top: 8px;
            padding-top: 0;
            padding-bottom: 15px;
            padding-left: 0;
          }
          .basket__table-cell:nth-child(5) {
            grid-row-start: 4;
            grid-column-start: 1;

            grid-row-end: 5;
            grid-column-end: 7;
            padding: 0;
          }
          .basket__table-cell:nth-child(6) {
            grid-row-start: 6;
            grid-column-start: 1;

            grid-row-end: 6;
            grid-column-end: 7;
            padding: 0;
          }
          .basket__table-price {
            .cell__price {
              //align-items: center;
              .discount {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
